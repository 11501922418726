.app-picker-section .app-picker-form {
  background-color: white;
  width: 550px;
  padding: 8px 40px 30px 40px;
  border-radius: var(--borderRadius);
  display: block;
  margin-bottom: 15px;
  box-shadow: rgb(0 0 0 / 20%) 0px 10px 15px 0;
  text-align: center;
}

.app-picker-section p.app-picker-info {
  color: var(--primaryColor);
  border-bottom: 1px solid var(--primaryColor);
  padding-bottom: 5px;
  font-weight: 500;
}

.app-picker-section .apps-selector {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.app-picker-section .apps-selector > .app-button-wrapper {
  transition: all .2s;
  flex: 1 1;
  cursor: pointer;
  text-align: center;
  align-items: center;
  padding: 5px;
  margin-right: 20px;
}

.app-picker-section .apps-selector > .app-button-wrapper:last-child {
  margin-right: 0;
}

.app-picker-section .apps-selector .app-button {
  border: 2px solid var(--smoothGray);
  border-radius: var(--borderRadius);
  margin-bottom: 10px;
  padding: 10px;
}

.app-picker-section .apps-selector p {
  color: var(--primaryColor);
}

.app-picker-section .apps-selector .app-button:hover {
  box-shadow: rgb(99 99 99 / 20%) 0 4px 5px 3px;
  border: 2px solid var(--primaryColor);
}

@media only screen and (max-width: 425px) {
  .app-picker-section .app-picker-form {
    margin: 15px !important;
    width: 100%;
  }
  .app-picker-section .apps-selector {
    display: block;
  }
  .app-picker-section .apps-selector > .app-button-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .app-picker-section .apps-selector .app-button {
    padding: 5px;
  }
}
