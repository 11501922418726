:root {
  --bodyBackgroundColor: #f5f5f5;
  --primaryColor: #002d6b;
  --textColor: #2B4E6F;
  --borderRadius: 4px;
  --placeholderColor: #C2C2BF;
  --smoothGray: #f3f3f3;
  --darkGray: #7E7E7E;
  --smoothShadow: rgb(99 99 99 / 20%) 0px 3px 5px 0;
}

.error {    
  color: #D98077;
  font-size: 12px;
  display: block;
  padding-top: 2px;
  font-style: italic;
}

a {
  text-decoration: none;
}

body {
  height: 100vh;
  background-color: var(--bodyBackgroundColor);
}

body, * {
  margin: 0;
  padding: 0;
  font-family: 'roboto', sans-serif;
  font-weight: normal;
  box-sizing: border-box;
  font-size: 16px;
}

.medium-text {
  font-size: 18px;
}

input,
select {
  height: 38px !important;
  padding: 0px 10px !important;
}
