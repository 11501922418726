.login-page {
  background-color: var(--primaryColor);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page .login-form {
  background-color: white;
  width: 370px;
  padding: 8px 40px 30px 40px;
  border-radius: var(--borderRadius);
  display: block;
  margin-bottom: 15px;
  box-shadow: rgb(0 0 0 / 20%) 0px 10px 15px 0;
}

.login-page .login-form .logo-section {
  margin-bottom: 15px;
}

.login-page .logo-section .usg-logo svg {
  width: 190px;
  display: block;
  margin: 0 auto !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.login-page .login-form .logo-section span {
  width: 190px;
  display: block;
  margin: 15px auto;
  text-align: center;
}

.login-page .input-text-wrapper label span {
  font-weight: 400;
}

.login-page .login-form input {
  padding: 0 !important;
  font-size: 16px !important;
  border: 0;
  border-bottom: 2px solid var(--smoothGray);
  border-radius: 0 !important;
  height: 31px !important;
}

.login-page .login-form input::placeholder {
  color: var(--smoothGray);
}

.login-page .login-form input:focus {
  border-bottom: 2px solid var(--primaryColor);
  transition: all .2s;
}

.login-page .login-form input {
  width: 100%;
  color: var(--darkGray);
}

.login-page .login-form button {
  width: 100%;
  margin-top: 12px;
}

.login-page .login-form .input-wrapper {
  margin-bottom: 20px;
}

.login-page .login-form .two-fa-description {
  margin-bottom: 20px
}

.login-page .login-form .input-wrapper.password-wrapper {
  margin-bottom: 15px !important;
}

.login-page .password-recovery {
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 15px;
  display: block;
}

.login-page .password-recovery:hover {
  text-decoration: underline;
}

.login-page .input-text-wrapper label .label-name {
  color: var(--primaryColor);
}

.login-page .input-text-wrapper label span {
  display: inline-block;
}

.login-page .password-wrapper label svg {
  margin-right: 2px;
}

@media only screen and (max-width: 425px) {
  .login-page .login-form {
    padding: 10px 25px 15px 25px;
    margin-bottom: 15px;
  }
}
